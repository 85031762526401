import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MobileTimetableCard = ({ colour, name, location, time }) => {
	return (
		<Card
			style={{
				boxShadow: "0px 3px 6px #00000029",
				opacity: 1,
				borderLeft: "5px solid",
				borderLeftColor: colour,
				borderRadius: "1rem",
			}}
			className="position-relative pt-2 pb-1 mt-3 ps-4"
		>
			<Row>
				<Col>
					<p className="jost-bold fs-5 mb-0">{name}</p>
					<p className="fs-5 my-0">{location}</p>
					<p className="fs-5 my-0">{time}</p>
				</Col>
			</Row>
		</Card>
	);
};

export default MobileTimetableCard;
