import React, { useEffect, useState } from "react";
import Timetable from "react-timetable-events";
import moment from "moment";

const TimetableComponent = ({ classes }) => {
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		if (typeof window !== "undefined") {
			setExpanded(true);
		}
		setTimeout(setExpanded(true), 500);
	}, []);

	function isBST(date) {
		let year = date.getFullYear();
		let lastSundayMarch = new Date(Date.UTC(year, 2, 31));
		let lastSundayOctober = new Date(Date.UTC(year, 9, 31));
		lastSundayMarch.setDate(
			lastSundayMarch.getDate() - lastSundayMarch.getUTCDay()
		);
		lastSundayOctober.setDate(
			lastSundayOctober.getDate() - lastSundayOctober.getUTCDay()
		);

		return date >= lastSundayMarch && date < lastSundayOctober;
	}

	function processDay(classes, day) {
		const today = new Date(); // Get today's date to check for BST
		const bstActive = isBST(today); // Check if it is currently BST

		return classes
			.filter((class1) => class1.timetableInfo[day].classAvailable)
			.map((class1) => {
				let startTime = new Date(
					`2018-02-23T${class1.timetableInfo[day].firstClass.startTime}`
				);
				let endTime = new Date(
					`2018-02-23T${class1.timetableInfo[day].firstClass.endTime}`
				);

				// Apply manual fix if it is currently BST
				if (bstActive) {
					startTime = new Date(startTime.getTime() - 3600000); // Subtract one hour
					endTime = new Date(endTime.getTime() - 3600000);
				}

				return {
					location: class1.timetableInfo[day].firstClass.location,
					id: class1.id,
					name: class1.title,
					type: class1.timetableInfo.colour,
					startTime,
					endTime,
					halfWidth:
						class1.timetableInfo[day].firstClass.halfWidth[0] || "full", // pass halfWidth for first class
				};
			})
			.concat(
				classes
					.filter(
						(class1) =>
							class1.timetableInfo[day].classAvailable &&
							class1.timetableInfo[day].secondClassAvailable
					)
					.map((class1) => {
						let startTime = new Date(
							`2018-02-23T${class1.timetableInfo[day].secondClass.startTime}`
						);
						let endTime = new Date(
							`2018-02-23T${class1.timetableInfo[day].secondClass.endTime}`
						);

						if (bstActive) {
							startTime = new Date(startTime.getTime() - 3600000);
							endTime = new Date(endTime.getTime() - 3600000);
						}

						return {
							location: class1.timetableInfo[day].secondClass.location,
							id: class1.id,
							name: class1.title,
							type: class1.timetableInfo.colour,
							startTime,
							endTime,
							halfWidth:
								class1.timetableInfo[day].secondClass.halfWidth[0] || "full", // pass halfWidth for second class
						};
					})
			);
	}

	const EventPreview = ({ event, defaultAttributes, classNames }) => {
		// Determine if the event is half-width and how it should be aligned ("Left" or "Right")
		console.log(event.halfWidth);
		const isHalfWidth =
			event.halfWidth === "Left" || event.halfWidth === "Right";
		const justifyContent =
			event.halfWidth === "Left"
				? "0%"
				: event.halfWidth === "Right"
				? "50%"
				: "0%";

		return (
			<div>
				{expanded && (
					<div
						{...defaultAttributes}
						style={{
							...defaultAttributes.style,
							background: event.type,
							width: isHalfWidth ? "50%" : "100%", // Set half-width if needed
							border: isHalfWidth ? "1px solid #efefef" : "0px", // Set half-width if needed
							display: "flex", // Use flexbox for alignment
							marginLeft: justifyContent,
							alignItems: "center", // Vertically center the content
						}}
						title={event.name}
						key={event.id}
						className={`${classNames.event}`}
					>
						<span className={classNames.event_info}>
							<span className="fw-bold" style={{ fontSize: "0.7rem" }}>
								{event.name}
							</span>
						</span>

						<span className={classNames.event_info}>
							<span className="fw-light" style={{ fontSize: "0.5rem" }}>
								{event.location}
							</span>
						</span>

						<span className={classNames.event_info}>
							<span className="fw-light" style={{ fontSize: "0.5rem" }}>
								{isBST(new Date())
									? `${moment(event.startTime)
											.add(1, "hours")
											.format("HH:mm")} - ${moment(event.endTime)
											.add(1, "hours")
											.format("HH:mm")}`
									: `${moment(event.startTime).format("HH:mm")} - ${moment(
											event.endTime
									  ).format("HH:mm")}`}
							</span>
						</span>
					</div>
				)}
			</div>
		);
	};

	return (
		<div>
			{typeof window !== "undefined" && (
				<Timetable
					renderEvent={EventPreview}
					hoursInterval={{
						from: 7,
						to: 21,
					}}
					style={{ height: "100vh" }}
					events={{
						mon: processDay(classes, "monday"),
						tue: processDay(classes, "tuesday"),
						wed: processDay(classes, "wednesday"),
						thu: processDay(classes, "thursday"),
						fri: processDay(classes, "friday"),
					}}
				/>
			)}
		</div>
	);
};

export default TimetableComponent;
