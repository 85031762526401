import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import Link from "../components/link";
import TimetableComponent from "../components/timetable";
import MobileTimetable from "../components/mobile-timetable";
import Navigation from "../components/navigation";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import { checkPropertiesForNull } from "../../utils";
import { v4 as uuidv4 } from "uuid";

const TimetablePage = () => {
	const data = useStaticQuery(graphql`
		query {
			classes: allWpTimetableClass {
				nodes {
					id
					title
					timetableInfo {
						colour
						friday {
							classAvailable
							secondClassAvailable
							firstClass {
								location
								startTime
								endTime
								halfWidth
							}
							secondClass {
								endTime
								location
								startTime
								halfWidth
							}
						}
						monday {
							classAvailable
							secondClassAvailable
							firstClass {
								location
								startTime
								endTime
								halfWidth
							}
							secondClass {
								endTime
								location
								startTime
								halfWidth
							}
						}
						thursday {
							classAvailable
							secondClassAvailable
							firstClass {
								location
								startTime
								endTime
								halfWidth
							}
							secondClass {
								endTime
								location
								startTime
								halfWidth
							}
						}
						tuesday {
							classAvailable
							secondClassAvailable
							firstClass {
								location
								startTime
								endTime
								halfWidth
							}
							secondClass {
								endTime
								location
								startTime
								halfWidth
							}
						}
						wednesday {
							classAvailable
							secondClassAvailable
							firstClass {
								location
								startTime
								endTime
								halfWidth
							}
							secondClass {
								endTime
								location
								startTime
								halfWidth
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "timetable" }) {
				timetableFields {
					timetableBanner {
						timetableBannerHeading
						timetableBannerText
					}
					timetableBookingClassesSection {
						timetableBookingClassesHeading
						timetableBookingClassesText
						timetableBookingClassesButton {
							title
							target
							url
						}
					}
					timetableLocation {
						timetableLocationHeading
						timetableLocations {
							timetableLocationAddress
							timetableLocationEmbeddedCode
						}
					}
				}
				seoFields {
					metaTitle
					metaDescription
					opengraphTitle
					opengraphDescription
					productSchema
					image {
						node {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, timetableFields },
	} = data;

	const { timetableBanner, timetableBookingClassesSection, timetableLocation } =
		timetableFields;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Timetable",
				item: {
					url: `${siteUrl}/timetable`,
					id: `${siteUrl}/timetable`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title={seoFields?.metaTitle}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>
			{timetableBanner &&
				!checkPropertiesForNull(timetableBanner, [
					"timetableBannerHeading",
				]) && (
					<section
						style={{
							background:
								"transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
							opacity: 1,
						}}
					>
						<Navigation background="none" />
						<Container>
							<Row>
								<Col className="text-center mb-5">
									<h1 className="text-primary display-4 jost-bold mt-5 mb-4">
										{timetableBanner?.timetableBannerHeading}
									</h1>
									<SafeHtmlParser
										htmlContent={timetableBanner?.timetableBannerText}
									/>
								</Col>
							</Row>
						</Container>
					</section>
				)}

			<section>
				<Container>
					<Row className="h-100">
						<Col className="text-center d-none d-lg-block h-100">
							<TimetableComponent classes={data.classes.nodes} />
						</Col>
						<Col className="d-lg-none">
							<MobileTimetable classes={data.classes.nodes} />
						</Col>
					</Row>
				</Container>
			</section>
			{timetableBookingClassesSection &&
				!checkPropertiesForNull(timetableBookingClassesSection, [
					"timetableBookingClassesHeading",
				]) && (
					<section className="my-8 text-center">
						<Container>
							<Row>
								<Col>
									<h2 className="jost-bold text-secondary">
										{
											timetableBookingClassesSection?.timetableBookingClassesHeading
										}
									</h2>
									<SafeHtmlParser
										htmlContent={
											timetableBookingClassesSection?.timetableBookingClassesText
										}
									/>
									{timetableBookingClassesSection?.timetableBookingClassesButton &&
										timetableBookingClassesSection?.timetableBookingClassesButton && (
											<Button
												className="px-5 cta-btn w-100 w-md-auto"
												as={Link}
												to={
													timetableBookingClassesSection
														?.timetableBookingClassesButton.url
												}
											>
												{
													timetableBookingClassesSection
														?.timetableBookingClassesButton.title
												}
											</Button>
										)}
								</Col>
							</Row>
						</Container>
					</section>
				)}
			{timetableLocation &&
				!checkPropertiesForNull(timetableLocation, ["timetableLocations"]) && (
					<section className="bg-contactBg py-5">
						<Container id="locations">
							<Row>
								<Col>
									<h3 className="jost-regular text-center mb-5">
										{timetableLocation?.timetableLocationHeading}
									</h3>
								</Col>
							</Row>
							{timetableLocation.timetableLocations &&
								timetableLocation.timetableLocations.length > 0 && (
									<Row className="gy-5">
										{timetableLocation.timetableLocations.map((item, i) => (
											<Col
												className="text-center d-flex flex-column justify-content-between"
												lg={6}
												key={uuidv4()}
											>
												<div className="mb-1">
													<SafeHtmlParser
														htmlContent={item.timetableLocationAddress}
													/>
												</div>

												<iframe
													style={{ height: "50vh" }}
													src={item.timetableLocationEmbeddedCode}
													frameBorder="0"
													aria-hidden="false"
													title="google-map"
													width="100%"
													loading="lazy"
												></iframe>
											</Col>
										))}
									</Row>
								)}
						</Container>
					</section>
				)}
		</Layout>
	);
};

export default TimetablePage;
