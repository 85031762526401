import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MobileTimetableCard from "./mobile-timetable-card";
import moment from "moment";

const MobileTimetable = ({ classes }) => {
	const mondayClasses = classes
		.filter((class1) => class1.timetableInfo.monday.classAvailable === true)
		.map((class1) => ({
			location: class1.timetableInfo.monday.firstClass.location,
			id: class1.id,
			name: class1.title,
			type: class1.timetableInfo.colour,
			startTime: new Date(
				`2018-02-23T${class1.timetableInfo.monday.firstClass.startTime}`
			),
			endTime: new Date(
				`2018-02-23T${class1.timetableInfo.monday.firstClass.endTime}`
			),
		}))
		.concat(
			classes
				.filter(
					(class1) =>
						class1.timetableInfo.monday.classAvailable === true &&
						class1.timetableInfo.monday.secondClassAvailable === true
				)
				.map((class1) => ({
					location: class1.timetableInfo.monday.secondClass.location,
					id: class1.id,
					name: class1.title,
					type: class1.timetableInfo.colour,
					startTime: new Date(
						`2018-02-23T${class1.timetableInfo.monday.secondClass.startTime}`
					),
					endTime: new Date(
						`2018-02-23T${class1.timetableInfo.monday.secondClass.endTime}`
					),
				}))
		);
	const mondayClassesOrdered = mondayClasses.sort((a, b) => {
		return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
	});

	const tuesdayClasses = classes
		.filter((class1) => class1.timetableInfo.tuesday.classAvailable === true)
		.map((class1) => ({
			location: class1.timetableInfo.tuesday.firstClass.location,
			id: class1.id,
			name: class1.title,
			type: class1.timetableInfo.colour,
			startTime: new Date(
				`2018-02-23T${class1.timetableInfo.tuesday.firstClass.startTime}`
			),
			endTime: new Date(
				`2018-02-23T${class1.timetableInfo.tuesday.firstClass.endTime}`
			),
		}))
		.concat(
			classes
				.filter(
					(class1) =>
						class1.timetableInfo.tuesday.classAvailable === true &&
						class1.timetableInfo.tuesday.secondClassAvailable === true
				)
				.map((class1) => ({
					location: class1.timetableInfo.tuesday.secondClass.location,
					id: class1.id,
					name: class1.title,
					type: class1.timetableInfo.colour,
					startTime: new Date(
						`2018-02-23T${class1.timetableInfo.tuesday.secondClass.startTime}`
					),
					endTime: new Date(
						`2018-02-23T${class1.timetableInfo.tuesday.secondClass.endTime}`
					),
				}))
		);
	const tuesdayClassesOrdered = tuesdayClasses.sort((a, b) => {
		return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
	});

	const wednesdayClasses = classes
		.filter((class1) => class1.timetableInfo.wednesday.classAvailable === true)
		.map((class1) => ({
			location: class1.timetableInfo.wednesday.firstClass.location,
			id: class1.id,
			name: class1.title,
			type: class1.timetableInfo.colour,
			startTime: new Date(
				`2018-02-23T${class1.timetableInfo.wednesday.firstClass.startTime}`
			),
			endTime: new Date(
				`2018-02-23T${class1.timetableInfo.wednesday.firstClass.endTime}`
			),
		}))
		.concat(
			classes
				.filter(
					(class1) =>
						class1.timetableInfo.wednesday.classAvailable === true &&
						class1.timetableInfo.wednesday.secondClassAvailable === true
				)
				.map((class1) => ({
					location: class1.timetableInfo.wednesday.secondClass.location,
					id: class1.id,
					name: class1.title,
					type: class1.timetableInfo.colour,
					startTime: new Date(
						`2018-02-23T${class1.timetableInfo.wednesday.secondClass.startTime}`
					),
					endTime: new Date(
						`2018-02-23T${class1.timetableInfo.wednesday.secondClass.endTime}`
					),
				}))
		);
	const wednesdayClassesOrdered = wednesdayClasses.sort((a, b) => {
		return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
	});

	const thursdayClasses = classes
		.filter((class1) => class1.timetableInfo.thursday.classAvailable === true)
		.map((class1) => ({
			location: class1.timetableInfo.thursday.firstClass.location,
			id: class1.id,
			name: class1.title,
			type: class1.timetableInfo.colour,
			startTime: new Date(
				`2018-02-23T${class1.timetableInfo.thursday.firstClass.startTime}`
			),
			endTime: new Date(
				`2018-02-23T${class1.timetableInfo.thursday.firstClass.endTime}`
			),
		}))
		.concat(
			classes
				.filter(
					(class1) =>
						class1.timetableInfo.thursday.classAvailable === true &&
						class1.timetableInfo.thursday.secondClassAvailable === true
				)
				.map((class1) => ({
					location: class1.timetableInfo.thursday.secondClass.location,
					id: class1.id,
					name: class1.title,
					type: class1.timetableInfo.colour,
					startTime: new Date(
						`2018-02-23T${class1.timetableInfo.thursday.secondClass.startTime}`
					),
					endTime: new Date(
						`2018-02-23T${class1.timetableInfo.thursday.secondClass.endTime}`
					),
				}))
		);
	const thursdayClassesOrdered = thursdayClasses.sort((a, b) => {
		return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
	});

	const fridayClasses = classes
		.filter((class1) => class1.timetableInfo.friday.classAvailable === true)
		.map((class1) => ({
			location: class1.timetableInfo.friday.firstClass.location,
			id: class1.id,
			name: class1.title,
			type: class1.timetableInfo.colour,
			startTime: new Date(
				`2018-02-23T${class1.timetableInfo.friday.firstClass.startTime}`
			),
			endTime: new Date(
				`2018-02-23T${class1.timetableInfo.friday.firstClass.endTime}`
			),
		}))
		.concat(
			classes
				.filter(
					(class1) =>
						class1.timetableInfo.friday.classAvailable === true &&
						class1.timetableInfo.friday.secondClassAvailable === true
				)
				.map((class1) => ({
					location: class1.timetableInfo.friday.secondClass.location,
					id: class1.id,
					name: class1.title,
					type: class1.timetableInfo.colour,
					startTime: new Date(
						`2018-02-23T${class1.timetableInfo.friday.secondClass.startTime}`
					),
					endTime: new Date(
						`2018-02-23T${class1.timetableInfo.friday.secondClass.endTime}`
					),
				}))
		);
	const fridayClassesOrdered = fridayClasses.sort((a, b) => {
		return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
	});

	return (
		<div>
			<section className=" ">
				<p className="jost-medium text-center fs-1 ">Monday</p>
				{mondayClassesOrdered.map((class1) => (
					<MobileTimetableCard
						colour={class1.type}
						name={class1.name}
						location={class1.location}
						time={`${moment(class1.startTime).format("HH:mm")} - 
						${moment(class1.endTime).format("HH:mm")}`}
					/>
				))}
			</section>
			<section className="mt-5 ">
				<p className="jost-medium text-center fs-1 ">Tuesday</p>
				{tuesdayClassesOrdered.map((class1) => (
					<MobileTimetableCard
						colour={class1.type}
						name={class1.name}
						location={class1.location}
						time={`${moment(class1.startTime).format("HH:mm")} - 
						${moment(class1.endTime).format("HH:mm")}`}
					/>
				))}
			</section>
			<section className="mt-5 ">
				<p className="jost-medium text-center fs-1 ">Wednesday</p>
				{wednesdayClassesOrdered.map((class1) => (
					<MobileTimetableCard
						colour={class1.type}
						name={class1.name}
						location={class1.location}
						time={`${moment(class1.startTime).format("HH:mm")} - 
						${moment(class1.endTime).format("HH:mm")}`}
					/>
				))}
			</section>
			<section className="mt-5 ">
				<p className="jost-medium text-center  fs-1 ">Thursday</p>
				{thursdayClassesOrdered.map((class1) => (
					<MobileTimetableCard
						colour={class1.type}
						name={class1.name}
						location={class1.location}
						time={`${moment(class1.startTime).format("HH:mm")} - 
						${moment(class1.endTime).format("HH:mm")}`}
					/>
				))}
			</section>
			<section className="mt-5 ">
				<p className="jost-medium text-center fs-1 ">Friday</p>
				{fridayClassesOrdered.map((class1) => (
					<MobileTimetableCard
						colour={class1.type}
						name={class1.name}
						location={class1.location}
						time={`${moment(class1.startTime).format("HH:mm")} - 
						${moment(class1.endTime).format("HH:mm")}`}
					/>
				))}
			</section>
		</div>
	);
};

export default MobileTimetable;
